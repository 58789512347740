.neusta-icon-buttons {
    display   : flex;
    flex-wrap : wrap;
    width     : calc(100% + 12px);
    transform : translateX(-12px);

    @media only screen and (min-width : 1024px) {
        flex-wrap : nowrap;
        width     : calc(100% + 24px);
        transform : translateX(-24px);
    }

    .neusta-icon-buttons-item {
        background-color : $beige1;
        padding          : 20px 24px;
        border-radius    : 4px;
        margin-left      : 12px;
        margin-bottom    : 12px;
        font-size        : 18px;
        line-height      : 24px;
        width            : calc(50% - 12px);
        text-align       : center;

        @media only screen and (min-width : 1024px) {
            font-size     : 24px;
            margin-left   : 24px;
            margin-bottom : 24px;
        }

        &.has-icon {
            height  : 210px;
            padding : 30px 24px 0 24px;

            @media only screen and (min-width : 1024px) {
                height : 240px;
            }

            .c-icon {
                font-size : 70px;
                color     : $gray;

                @media only screen and (min-width : 1024px) {
                    font-size : 90px;
                }

                &:before {
                    margin-right : 0;
                }
            }
        }
    }

    a {
        width           : calc(50% - 12px);
        margin-left     : 12px;
        margin-bottom   : 12px;
        text-decoration : none;

        @media only screen and (min-width : 1024px) {
            margin-left   : 24px;
            margin-bottom : 24px;
        }

        .neusta-icon-buttons-item {
            display        : flex;
            flex-direction : column;
            align-items    : center;
            width          : 100%;
            margin-left    : 0;
            margin-bottom  : 0;
            height         : 100%;
        }
    }

    .title {
        color           : $gray;
        font-family     : $font-base;
        font-size       : 16px;
        font-weight     : bold;
        letter-spacing  : 0;
        line-height     : 22px;
        text-align      : center;
        height          : 100%;
        display         : flex;
        align-items     : center;
        justify-content : center;

        @media only screen and (min-width : 1024px) {
            font-size   : 18px;
            line-height : 22px;
        }
    }
}
