$gray       : #646363;
$beige1     : #e9e3dd;
$purple     : #752A56;
$green      : #86B741;

$color-base : $gray;


/**
 * Color
 *
 * The function is defined in "_tools.function_color.sass"
 *
 * Usage example: color(base)
 *
 * @type    sass-map
 */

// map colors
$COLORS     : (
    base: (
        default: $gray
    ),
    red: (
        default: #E2001A,
    ),
    white: (
        default: #FFFFFF
    ),
    beige-1: (
        default: $beige1,
    ),
    beige-2: (
        default: #A80000,
    ),
    purple: (
        default: #752A56
    ),
    green: (
        default: #86B741
    ),
    blue: (
        default: #154461
    )
) !default;
